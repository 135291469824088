<template>
  <div>
    <Header></Header>
    <div class="banner">
      <img :src="`${$url}/banner/4.png`" alt="">
      <div class="all-title-ani">医药新零售</div>
      <div class="all-title-ani all-ani-delay" style="width: 8rem;">通过线上线下及公域私域相融合，提供导购数字化、营销数字化、会员数字化等赋能工具，帮助医药门店实现全时全域经营，多位一体数字化构建可持续智慧增长。</div>
    </div>
    <div class="retail">
      <div class="retail-advantages">
        <div class="All-title">产品优势</div>
        <div class="All-box">
          <div>
            <div>
              <img :src="`${$url}/internet/1.png`" alt="">
              <img :src="`${$url}/internet/16.png`" alt="">
              <div>应用层</div>
              <div>基于历史交付和实践经验所设计的应用模板，可帮助客户有效缩减应用的开发时间。</div>
            </div>
          </div>
          <div>
            <div>
              <img :src="`${$url}/internet/2.png`" alt="">
              <img :src="`${$url}/internet/17.png`" alt="">
              <div>平台层</div>
              <div>完善的可视化开发和管理功能，帮助客户轻松完成设备端的"管、控、营"一体化。</div>
            </div>
          </div>
          <div>
            <div>
              <img :src="`${$url}/internet/3.png`" alt="">
              <img :src="`${$url}/internet/18.png`" alt="">
              <div>传输层</div>
              <div>借助智能网关及SDK，帮助客户快速接入各类设备，让客户的数据更迅速、安全的流动。</div>
            </div>
          </div>
          <div>
            <div>
              <img :src="`${$url}/internet/4.png`" alt="">
              <img :src="`${$url}/internet/19.png`" alt="">
              <div>感知层</div>
              <div>广泛兼容标准协议，面对各种复杂的设备部署方案游刃有余, 帮助客户有效降低实施成本。</div>
            </div>
          </div>
          <div>
            <div>
              <img :src="`${$url}/internet/5.png`" alt="">
              <img :src="`${$url}/internet/20.png`" alt="">
              <div>体验层</div>
              <div>使用云数智康平台可以轻松的开发跨平台的物联网应用，以高度灵活的适配数据需求。</div>
            </div>
          </div>
          <div>
            <div>
              <img :src="`${$url}/internet/6.png`" alt="">
              <img :src="`${$url}/internet/21.png`" alt="">
              <div>开发层</div>
              <div>告别各类工具的沉重束缚，使用桌面端，移动端访问, 有效降低开发运维的成本和复杂度。</div>
            </div>
          </div>
        </div>
      </div>
      <div class="product-empowerment">
        <div class="All-title">产品赋能</div>
        <div class="All-box">
          <div>
            <div>
              <img :src="`${$url}/retail/1.png`" alt="">
              <img :src="`${$url}/retail/15.png`" alt="">
              <div>全智能化管理</div>
              <div style="font-size:0.2933rem">平台上架商品与实体门店商品库存实时同步。平台订单自动化处理，不影响线下销售。经营数据的在线管理，以实时了解经营状况。</div>
            </div>
          </div>
          <div>
            <div>
              <img :src="`${$url}/retail/2.png`" alt="">
              <img :src="`${$url}/retail/18.png`" alt="">
              <div>多重运营营销</div>
              <div>线上商品优化，多重营销，可根据不同门店制定相对应的运营策略和计划。</div>
            </div>
          </div>
          <div>
            <div>
              <img :src="`${$url}/retail/3.png`" alt="">
              <img :src="`${$url}/retail/16.png`" alt="">
              <div>共享药学中心</div>
              <div style="font-size:0.2933rem">“在线购药”一站式满足用户从健康咨询、快速问诊到线上购药的多重需求，真正实现线上医疗健康服务的安全、便捷、规范。</div>
            </div>
          </div>
          <div>
            <div>
              <img :src="`${$url}/retail/4.png`" alt="">
              <img :src="`${$url}/retail/19.png`" alt="">
              <div>满足多样购药场景</div>
              <div>提供门店自助购药、到店自提、配送到家三大模式，满足客户多样化场景与需求。</div>
            </div>
          </div>
          <div>
            <div>
              <img :src="`${$url}/retail/5.png`" alt="">
              <img :src="`${$url}/retail/17.png`" alt="">
              <div>健康信息管理</div>
              <div>支持在线问诊、在线处方、O2O送药上门服务等，全面建立顾客的健康信息。</div>
            </div>
          </div>
          <div>
            <div>
              <img :src="`${$url}/retail/6.png`" alt="">
              <img :src="`${$url}/retail/20.png`" alt="">
              <div>对接医保</div>
              <div>支持各地医保对接，医保药品自动生成医保单据，店员无需二次录入。</div>
            </div>
          </div>
        </div>
      </div>
      <div class="devices-products">
        <div class="All-title">医疗器械与产品</div>
        <div class="devices-box">
          <div>
            <img :src="`${$url}/retail/7.png`" alt="" @click="gotoDetails(1,'头皮毛发检测仪（居家款）')">
            <div>头皮毛发检测仪（居家款）</div>
          </div>
          <div>
            <img :src="`${$url}/retail/8.png`" alt="" @click="gotoDetails(2,'头皮毛发检测仪（医疗款）')">
            <div>头皮毛发检测仪（医疗款）</div>
          </div>
          <div>
            <img :src="`${$url}/retail/9.png`" alt="" @click="gotoDetails(3,'头皮毛发按摩仪')">
            <div>头皮毛发按摩仪</div>
          </div>
          <div>
            <img :src="`${$url}/retail/10.png`" alt="" @click="gotoDetails(4,'头皮检测仪器')">
            <div>头皮检测仪器</div>
          </div>
          <div>
            <img :src="`${$url}/retail/11.png`" alt="" @click="gotoDetails(5,'理然香氛去屑洗发水')">
            <div>理然香氛去屑洗发水</div>
          </div>
          <div>
            <img :src="`${$url}/retail/12.png`" alt="" @click="gotoDetails(6,'生姜洗发水育发液')">
            <div>生姜洗发水育发液</div>
          </div>
          <div>
            <img :src="`${$url}/retail/13.png`" alt="" @click="gotoDetails(7,'谜草集 毛囊炎洗发水')">
            <div>谜草集 毛囊炎洗发水</div>
          </div>
          <div>
            <img :src="`${$url}/retail/14.png`" alt="" @click="gotoDetails(8,'Fino美容复合精华洗发水')">
            <div>Fino美容复合精华洗发水</div>
          </div>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from '@/components/header.vue'
import Footer from '@/components/footer.vue'
export default {
  components: {
    Header,
    Footer
  },
  methods: {
    gotoDetails(id,text) {
      this.$router.push({ path: '/details',query: { id: id,text: text } })
    }
  }
}
</script>

<style lang="scss">
.retail {
  .retail-advantages {
    padding-top: 100px;
    background: #f7faff;
    padding-bottom: 90px;
  }
  .product-empowerment {
    width: 100%;
    background: url('https://static.drlianzhuren.com/HairTransplant/CloudNumber-m/internet/14.png')
      no-repeat;
    background-size: 100% 100%;
    overflow: hidden;
    text-align: center;
    padding-bottom: 90px;
    padding-top: 100px;
    .All-box {
      > div {
        height: 410px;
      }
    }
  }
  .devices-products {
    padding-top: 100px;
    padding-bottom: 60px;
    background: #f5f6f8;
    .devices-box {
      margin-top: 64px;

      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      > div {
        width: 340px;
        background: #ffffff;
        box-shadow: 0px 8px 16px 2px rgba(5, 36, 72, 0.12);
        border-radius: 8px 8px 8px 8px;
        opacity: 1;
        margin-right: 22px;
        margin-bottom: 24px;
        img {
          width: 340px;
          height: 340px;
        }
        > div:nth-child(2) {
          height: 78px;
          font-size: 30px;
          font-weight: 400;
          color: #181818;
          line-height: 78px;
          text-align: center;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          width: 296px;
          margin: 0 auto;
        }
      }
      > div:nth-child(2n) {
        margin-right: 0;
      }
    }
  }
}
</style>
